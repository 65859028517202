/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
    makeStyles,
    Theme,
    createStyles,
    Grid,
    TextField,
    Divider
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { mainTheme } from '../../Theme';
import { Field, FieldInputProps, Form, FormSpy } from 'react-final-form';
import { IAutoCompleteItem } from '../../typings/autoComplete';
import { showNotification } from '../../App';
import { client } from '../..';
import { FormApi } from 'final-form';
// import DialogBox from '../../Dashboard/Component/DialogBox';
import { FollowUp } from './FollowUpDialog';
import { EnquiryDefaultConfig, FollowUpEnquiryMutation } from '../EnquirySummaryRepository';
import moment from 'moment';
import DialogBox from '../../Dashboard/Component/DialogBox';
import { FetchPolicy } from 'apollo-client';
import gql from 'graphql-tag';
import { EmailTemplateTypeEnum } from '../../emailTemplatesConfiguration/EmailTemplateRespository';
import { RvLoader } from '../../components/Loader';
import { TextFieldWithMention } from '../../components/TextFieldWithMention';
import { OutcomeRadio } from '../radio/OutcomeRadio';
import { KeyboardDateTimePicker } from '@material-ui/pickers/DateTimePicker/DateTimePicker';
import { MentionItem } from 'react-mentions';
import { ReasonSelector } from '../selectors/ReasonSelector';
// import { Editor } from '@tinymce/tinymce-react';
import { addBusinessDays } from '../drawers/EnquiryFormDrawer';
import { EditorWrapper } from '../../components/Editor';
import { ReminderSelector } from '../../components/ReminderSelector';
import { DropzoneArea } from 'material-ui-dropzone';
import { DocumentFile } from '../../types/DocumentFile';
import { useApplicationSettings } from '../../applicationSettings/ApplicationSettingsRepository';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        textField: {
            width: '100%',
        },
        commendField: {
            width: '100%',
        },
        dialogTitle: {
            color: mainTheme.TemplateColor.Primary,
        },
        dropzone: {
            paddingTop: 10,
            paddingBottom: 10,
        },
        matterLabel: {
            display: 'flex',
            alignItems: 'center',
            height: '100%',
        },
        hideDate: {
            display: 'none'
        },
        divider: {
            width: '100%',
        },
        radioIcon: {
            color: mainTheme.BrandColors.TemplatePrimary
        },
        radioGroup: {
            flexFlow: 'row wrap',
            justifyContent: 'center',
        },
        selector: {
            height: '100%',
            display: 'flex',
            alignItems: 'flex-end'
        },
        dropZoneFont: {            
            minHeight: 0,
            '& .MuiDropzoneArea-text': {
                fontSize: '1em',
            }
        },
        dropZone: {
            minHeight: 'fit-content !important' ,
            '& .MuiDropzoneArea-root' : {
                minHeight: 'fit-content !important'
            }
        },
    })
);

export enum ControlType {
    quill = 0,
    tinyMce = 1
}

interface SendEmailState {
    isFormChanged: boolean;
    showDiscard: boolean;

    emailContent: string;
    emailSubject?: string | null;
    fromEmailAddress?: string | null;
    toEmailAddress?: string | null;
    ccEmailAddress?: string | null;

    controlType: ControlType;
    emailContentCopy: string;
    files: DocumentFile[];
    fileSizeLimit: number;
}

export interface SendEmailProps {
    enquiryGuid: string;
    emailTemplateGuid: string;
    emailTemplateName: string;
    followUpAction: string;
    followUpActionSystemName: string;
    followUpDate: Date | null;
    toEmailAddress: string | null;
    fromEmailAddress: string | null;
    emailType: EmailTemplateTypeEnum;

    onDialogClose?: () => void;
    isSendEmailDialogOpen: boolean;
    matter?: IAutoCompleteItem;
    enquiryDefaultConfig?: EnquiryDefaultConfig;
    canCreateMatter: boolean | undefined;
}

// tslint:disable-next-line: no-anyW
export const SendEmailDialog: React.FC<SendEmailProps> = ( props ) => {
    const classes = useStyles();

    const [isLoading, setIsLoading] = useState(true);

    const applicationSettingsQuery = useApplicationSettings();

    const [state, setState] = useState<SendEmailState>({
        showDiscard: false,
        isFormChanged: false,

        emailContent: '',
        emailSubject: null,
        fromEmailAddress: null,
        toEmailAddress: props.toEmailAddress,
        ccEmailAddress: props.fromEmailAddress, // Set the from Email to cc as well. This helps with document management systems

        controlType: ControlType.quill,
        emailContentCopy: '',
        files: [],
        fileSizeLimit: 0,
    });

    useEffect(() => {
        if (!applicationSettingsQuery.loading && !applicationSettingsQuery.error && applicationSettingsQuery.data) {

            console.log('time', applicationSettingsQuery.data);

            setState((prevState) => {
                return {
                    ...prevState,
                    fileSizeLimit: applicationSettingsQuery.data?.settings.applications.systemOptions.config.emailSizeLimit || 0
                }
            });
        }
    }, [applicationSettingsQuery.data, applicationSettingsQuery.loading, applicationSettingsQuery.error]);

    useEffect(() => {
        // - Ran only once
        setIsLoading(true);
        fetchEmailContentData();
        
    // tslint:disable-next-line: align
    }, [props.emailTemplateGuid]);
      
    const fetchEmailContentData = () => {
        var params: EmailDetailQueryParams = {
            enquiryGuid: props.enquiryGuid,
            emailTemplateGuid: props.emailTemplateGuid,
            emailType: EmailTemplateTypeEnum[props.emailType]
        };

        fetchEmailContent(
            params,
            true,
            (data: TemplatedData) => {
                handleEmailDetailFetch(data);
            },
            (reason) => {
                showNotification('Failed to Fetch App User Settings', reason, 'error');
                setIsLoading(false);
            }
        );
    };

    const handleEmailDetailFetch = (data?: TemplatedData) => {
        if (
            data !== null &&
            data?.templates !== undefined &&
            data.templates.emailDetail !== undefined
        ) {
            const emailDetail = data.templates.emailDetail;

            setState((prevState) => {
                return {
                    ...prevState,
                    // fromEmailAddress: data.templates.emailDetail.fromAddress,
                    // toAddresses: emailDetail.toAddresses,
                    ccAddresses: emailDetail.ccAddresses,
                    emailType: emailDetail.emailType,
                    emailStyle: emailDetail.emailStyle,
                    emailSubject: emailDetail.emailSubject,
                    emailContent: emailDetail.emailContent,

                    emailContentCopy: emailDetail.emailContent
                };
            });

            setIsLoading(false);
        }
    };

    const onSubmit = (values: FollowUp) => {
        saveFollowUp(values);
    };

    // Send email is also a follow up. We send the email at the end of the mutation
    const saveFollowUp = (values: FollowUp) => {        
        // Save the File
        client.mutate({
            mutation: FollowUpEnquiryMutation,
            variables: {
                input: getFollowUpEnquiryInput(values)
            },
        })
        // tslint:disable-next-line: no-any
        .then((results: { data: any; }) => { 
            if (results.data) {
                if (results.data.error === null || results.data.error === undefined) {
                    showNotification(null, 'Successfully submitted', 'info'); 

                    onFollowUpClosed();
                } else {
                    showNotification('Failed to Save Follow up', results.data.error, 'error'); 
                }
            }            
        })
        // tslint:disable-next-line:no-any
        .catch((reason: any) => { showNotification('Failed to Save Follow up', reason, 'error'); });        
    };

    const getFollowUpEnquiryInput = (values: FollowUp) => {
        return {
            enquiryGuid: props.enquiryGuid,
            followUpAction: props.followUpAction,
            followUpActionSystemName: props.followUpActionSystemName,
            followUpDate: values.followUpDate === null ? moment().toDate() : moment(values.followUpDate).toDate(),
            outcome: values.outcome,
            outcomeDate: values.outcome === 'New Client' || values.outcome === 'New Matter' || values.outcome === 'Not Proceeding' 
                            ? !values.followUpDate ? null : moment(values.followUpDate).toDate()
                            : !values.outcomeDate ? null : moment(values.outcomeDate).toDate(),
            outcomeNotes: values.outcomeNotes,
            reason: values.reason,
            reasonGuid: values.reasonGuidID,
            followUpNotes: values.followUpNotes,

            sendEmailResponse: true,
            emailContent: state.emailContent, // using value.emailContent is giving an issue
            emailSubject: values.emailSubject,
            emailTemplateName: props.emailTemplateName,
            fromEmailAddress: values.fromEmailAddress,
            toEmailAddresses: values.toEmailAddress,
            ccEmailAddresses: values.ccEmailAddress,

            reminderOffset: values.outcome === 'More Follow Up'
                ? (values.followUpDueReminder ? values.followUpDueReminder.value : null)
                : null,

            files: GetFiles(values.files),
        };
    };

    const GetFiles = (files: DocumentFile[]) => {
        return files.map((document: DocumentFile) => {
            return {
                file: document.file,
                name: document.name,
                guidID: document.guidID,
                status: document.status
            };
        });
    };

    const onFollowUpClosed = () => {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
    };

    // tslint:disable-next-line: no-any
    const onFormValueChanged = (form: FormApi<any>, changeProps: any) => {
        if (!changeProps.pristine) {
            form.change('isFormChanged', true);
            setState((prevState) => {
                return {
                    ...prevState,
                    isFormChanged: true
                };
            });
        }
    };

    const onClose = () => {
        if (state.isFormChanged) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: true
                };
            });
        } else {
            onFollowUpClosed();
        }
    };

    // tslint:disable-next-line: no-any
    const onDiscardChanges = (agree: boolean) => {
        if (agree) {
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false
                };
            });

            onFollowUpClosed();
            
        } else {
            // showDiscard false
            // form.change('showDiscard', false);
            setState((prevState) => {
                return {
                    ...prevState,
                    showDiscard: false
                };
            });
        }
    };

    // tslint:disable-next-line: no-any
    const required = (value: any) => {
        return value ? undefined : 'Required';
    };

    const displayLoader = () => {
        if (isLoading) {
            // return <RvLoader />;
            return (
                <Dialog
                    open={props.isSendEmailDialogOpen}
                    onClose={() => onClose()}
                    className={classes.root}
                    fullWidth={true}
                    maxWidth="md"
                >
                    <DialogTitle
                        id="form-dialog-title"
                        className={classes.dialogTitle}
                    >
                        Send Email
                    </DialogTitle>
                    <DialogContent dividers={true}>                                            
                        <Grid container={true} spacing={1}>
                            <Grid item={true} sm={12} md={12} lg={12} xs={12} justify="center" alignItems="center">
                                <RvLoader />
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>

            );
        } else {
            return null;
        }
    };

    // tslint:disable-next-line
    const emailRegex = /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    // tslint:disable-next-line: no-any
    const EmailValidateWithRequiredNew = (value: any) => {
        let error: string = '';

        if (value === undefined || value === null) {
            return value ? '' : 'Email is required';
        } else if (value.length === 0) {
            return value ? '' : 'Email is required'; 
        } else if (!emailRegex.test(value)) {
            error = 'Email is not valid';
        }
        return error;
    };

    // tslint:disable-next-line: no-any
    const EmailValidate = (value: any) => {
        let error: string = '';

        if (value !== undefined && value !== null) {
            if (!emailRegex.test(value)) {
                error = 'Email is not valid';
            }
        }
        
        return error;
    };

    // tslint:disable-next-line: no-any
    const requiredDateValidator = (value: any) => {
        if (value) {
            // check for a valid date and check for sql's min date
            var date = moment(value);

            if (date.isValid() && date.year() >= 1753) {
                return undefined;
            } else {
                return 'Incorrect Date';
            }
        } else {
            return 'Required';
        }
    };

    // tslint:disable-next-line: no-any
    const onOutcomeChange = (form: FormApi<any>, values: any, event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {        
        const outcome = event && event.currentTarget && event.currentTarget.value ? event.currentTarget.value : '';
        form.change('outcome', outcome);

        form.change('reason', null);
        form.change('reasonGuid', null);
        // if (outcome && !values.outcomeDate) {
        //     form.change('outcomeDate', values.followUpDate);
        // }
    };

    // tslint:disable-next-line: no-any
    const onDropdownChange = (selection: IAutoCompleteItem, name: string, input: FieldInputProps<any, HTMLElement>, form: FormApi<any>, 
                              valueGuid: string) => {
        input.onChange(selection.label.length > 0 ? selection.label : null);

        if (name === 'reason') {
            form.change('reasonGuidID', valueGuid.length > 0 
                ? valueGuid 
                : null);
        } else if (name === 'notProceedingReason') {
            form.change('notProceedingReasonGuid', valueGuid.length > 0 
                ? valueGuid 
                : null);
        }       
    };

    const getReminderValue = () => {
        if (props.enquiryDefaultConfig && props.enquiryDefaultConfig.reminder) {
            return { label: props.enquiryDefaultConfig.reminder, value: props.enquiryDefaultConfig.reminder };
        } else {
            return { label: 'None', value: 'None'};
        }
    };

    const getOutcomeDateDefaultValue = () => {
        // check outcome date (FollowUpDue)
        let outcomeDate: Date | null = null;
        
        if (props.enquiryDefaultConfig) {

            let hours = 0;
            let minutes = 0;
            let validTimeOfDay = false;
            
            if (props.enquiryDefaultConfig.timeOfDay && props.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
                var timeOfDaySplit = props.enquiryDefaultConfig.timeOfDay ? props.enquiryDefaultConfig.timeOfDay.split(':') : null;
            
                if (timeOfDaySplit) {
                    validTimeOfDay = true;
                    hours = Number(timeOfDaySplit[0]);
                    minutes = Number(timeOfDaySplit[1]);
                }
            }           

            if (props.enquiryDefaultConfig.isCalculatedDays) {
                let date = addBusinessDays(moment().toDate(), props.enquiryDefaultConfig.addDays ? props.enquiryDefaultConfig.addDays : 0);
               
                if (validTimeOfDay) {
                    outcomeDate = date
                        .set('hour', hours)
                        .set('minute', minutes)
                        .toDate();
                } else {
                    outcomeDate = date.toDate();
                }
            } else if (props.enquiryDefaultConfig.isCalculatedHours) {
                let addhours = props.enquiryDefaultConfig.addHours ? props.enquiryDefaultConfig.addHours : 0;
    
                outcomeDate = moment()
                    .add(addhours, 'hour')
                    .toDate();
            }

            // Manual default time is set through getOutcomeDefaultTime()
        }       

        return outcomeDate;
    };

    const getOutcomeDefaultTime = () => {
        let defaultTime = new Date().setHours(17, 0, 0, 0);

        let hours = 0;
        let minutes = 0;        

        if (props.enquiryDefaultConfig && props.enquiryDefaultConfig.isManualEntry 
            && props.enquiryDefaultConfig.timeOfDay && props.enquiryDefaultConfig.timeOfDay.toLowerCase() !== 'invalid date') {
            var timeOfDaySplit = props.enquiryDefaultConfig.timeOfDay ? props.enquiryDefaultConfig.timeOfDay.split(':') : null;

            if (timeOfDaySplit) {
                hours = Number(timeOfDaySplit[0]);
                minutes = Number(timeOfDaySplit[1]);
            }

            defaultTime = new Date().setHours(hours, minutes, 0, 0);
        } 

        return defaultTime;
    };

    return (
        <React.Fragment>
            {
                isLoading || applicationSettingsQuery.loading
                    ? displayLoader()
                    : (
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{                    
                                followUpAction: props.followUpAction,
                                followUpDate: props.followUpDate, 
                                followUpActionSystemName: props.followUpActionSystemName,                                                   
                                followUpNotes: '',
                                hasFollowUpNotesMentions: false,
                                outcome: 'More Follow Up',
                                outcomeDate: getOutcomeDateDefaultValue(),
                                outcomeNotes: '',
                                reason: null,
                                reasonGuid: null,
                                isFormChanged: false,
                                staff: null,
                                startTime: moment().toDate(), 
                                endTime: moment().add(1, 'hour').toDate(),
                                isAllDay: false,
                                officeLocation: null,
                                feeApplicable: null,
                                isSendConfirmation: false,
                                meetingNotes: null,

                                emailContent: state.emailContent,
                                emailSubject: state.emailSubject,
                                fromEmailAddress: props.fromEmailAddress,
                                toEmailAddress: props.toEmailAddress,
                                ccEmailAddress: state.ccEmailAddress,

                                followUpDueReminder: getReminderValue(),
                                files: []
                            }}
                            initialValuesEqual={() => true}
                            subscription={{submitting: true, pristine: true}}
                            validate={(values) => {
                                let errors = {
                                    fromEmailAddress: '',
                                    toEmailAddress: '',
                                    ccEmailAddress: ''
                                };

                                if ((!values.fromEmailAddress) 
                                    && (!values.toEmailAddress)) {
                                    errors.fromEmailAddress = 'From email address is required';
                                    errors.toEmailAddress = 'To email address is required';
                                } else if (values
                                    && (values.fromEmailAddress && values.fromEmailAddress.length === 0)
                                    && (values.toEmailAddress && values.toEmailAddress.length === 0)) {
                                    errors.fromEmailAddress = 'From email address is required';
                                    errors.toEmailAddress = 'To email address is required';
                                } else if (
                                        (values.fromEmailAddress !== undefined && values.fromEmailAddress !== null 
                                            && values.fromEmailAddress.length > 0) 
                                        || (values.toEmailAddress !== undefined && values.toEmailAddress !== null 
                                            && values.toEmailAddress.length > 0) 
                                        || (values.ccEmailAddress !== undefined && values.ccEmailAddress !== null 
                                            && values.ccEmailAddress.length > 0) 
                                    ) {
                                    errors.fromEmailAddress = EmailValidateWithRequiredNew(values.fromEmailAddress);
                                    errors.toEmailAddress = EmailValidateWithRequiredNew(values.toEmailAddress);
                                    errors.ccEmailAddress = EmailValidate(values.ccEmailAddress);
                                }
                                    
                                if (errors.fromEmailAddress === '' && errors.toEmailAddress === '' && errors.ccEmailAddress === '') {
                                    return undefined;
                                }

                                return errors;
                            }}
                            render={({handleSubmit, form, submitting, pristine, values}) => (
                                <form onSubmit={event => handleSubmit(event)} id="sendEmailDialog" autoComplete="off">
                                    {displayLoader()}
                                    <FormSpy 
                                        subscription={{ pristine: true, values: true }}
                                        // tslint:disable-next-line: no-shadowed-variable
                                        onChange={props => {
                                            onFormValueChanged(form, props);
                                        }}
                                    />                        
                                    <DialogBox
                                        title="Send Email"
                                        // tslint:disable-next-line:max-line-length
                                        content={`Are you sure you want to close the form?`}
                                        show={state.showDiscard ? state.showDiscard : false}
                                        isAgree={onDiscardChanges}
                                        disAgreeLabel={'No'}
                                        agreeLabel={'Yes'}
                                    />
                                    
                                    <Dialog
                                        open={props.isSendEmailDialogOpen}
                                        onClose={() => onClose()}
                                        className={classes.root}
                                        fullWidth={true}
                                        maxWidth="md"
                                        disableEnforceFocus={true}
                                    >
                                        <DialogTitle
                                            id="form-dialog-title"
                                            className={classes.dialogTitle}
                                        >
                                            Send Email
                                        </DialogTitle>
                                        <DialogContent dividers={true}>
                                            <Grid container={true} spacing={1}>
                                                <FormSpy subscription={{ values: true, validating: true }}>
                                                    {/* tslint:disable-next-line: no-shadowed-variable */}
                                                    {({ values }) => (
                                                        <>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="fromEmailAddress"
                                                                    type="email"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="From"
                                                                                type="email"
                                                                                required={true}
                                                                                className={classes.textField}
                                                                                error={meta.error && meta.touched}
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="toEmailAddress"
                                                                    type="email"
                                                                    subscription={{touched: true, error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="To"
                                                                                type="email"
                                                                                required={true}
                                                                                className={classes.textField}
                                                                                error={meta.error && meta.touched}
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="ccEmailAddress"
                                                                    type="email"
                                                                    subscription={{touched: true,  error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="Cc"
                                                                                type="email"
                                                                                required={undefined}
                                                                                className={classes.textField}
                                                                                error={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                }
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="emailSubject"
                                                                    type="text"
                                                                    validate={required}
                                                                    subscription={{touched: true,  error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <div>
                                                                            <TextField
                                                                                {...input}
                                                                                label="Subject"
                                                                                type="text"
                                                                                required={true}
                                                                                className={classes.textField}
                                                                                error={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                }
                                                                                autoComplete="abcd"
                                                                                helperText={
                                                                                    meta.error &&
                                                                                    meta.touched
                                                                                        ? meta.error
                                                                                        : ''
                                                                                }
                                                                            />
                                                                        </div>
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            {/* <Grid>
                                                                {
                                                                    <FormControl component="div">
                                                                        <RadioGroup 
                                                                            name="controlType" 
                                                                            value={state.controlType} 
                                                                            onChange={onControlTypeChange}
                                                                            className={classes.radioGroup}
                                                                        >
                                                                            // tslint:disable-next-line: max-line-length 
                                                                            <FormControlLabel 
                                                                                value={ControlType.quill} 
                                                                                control={<Radio color="primary" />} 
                                                                                label="Quill" 
                                                                            />
                                                                            // tslint:disable-next-line: max-line-length 
                                                                            <FormControlLabel 
                                                                                value={ControlType.tinyMce} 
                                                                                control={<Radio color="primary" />} 
                                                                                label="Jodit" 
                                                                            />
                                                                        </RadioGroup>
                                                                    </FormControl>    
                                                                }
                                                            </Grid> 
                                                            */}
                                                            <Grid item={true} xs={12}>
                                                                
                                                                {/* {state.controlType === ControlType.quill && (
                                                                    <Field
                                                                        name="emailContent"
                                                                        type="text"
                                                                        validate={required}
                                                                        subscription={{touched: true,  error: true, value: true}}
                                                                    >
                                                                        {({ input, meta }) => (
                                                                            <ReactQuill 
                                                                                // theme={'snow'}
                                                                                value={state.emailContent}
                                                                                onChange={(content: string) => {
                                                                                    setState((prevState) => {
                                                                                        return {
                                                                                            ...prevState,
                                                                                            emailContent: content
                                                                                        };
                                                                                    });
                                                                                }} 
                                                                                modules={modules}
                                                                                // formats={formats}
                                                                            />
                                                                        )}
                                                                    </Field>
                                                                )}
                                                                {state.controlType === ControlType.tinyMce && ( */}
                                                                <Field
                                                                    name="emailContent"
                                                                    type="text"
                                                                    validate={required}
                                                                    subscription={{touched: true,  error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <EditorWrapper
                                                                            // disabledAI={true}
                                                                            value={state.emailContent}
                                                                            onEditorChange={(content: string) => {
                                                                                setState((prevState) => {
                                                                                    return {
                                                                                        ...prevState,
                                                                                        emailContent: content
                                                                                    };
                                                                                });
                                                                            }}
                                                                        />
                                                                        // <Editor
                                                                        //     apiKey={'k74nddluc9fmtf75en31ew8mxzqcvovgpjkzomdtgeje7b0h'}
                                                                        //     value={state.emailContent}
                                                                        //     init={{
                                                                        //         height: 500,
                                                                        //         menubar: false,
                                                                        //         plugins: [
                                                                        //             'advlist autolink lists link image charmap print preview anchor',
                                                                        //             'searchreplace visualblocks code fullscreen',
                                                                        //             'insertdatetime media table paste code help wordcount'
                                                                        //         ],
                                                                        //         toolbar: 'undo redo | formatselect | ' +
                                                                        //             'bold italic backcolor | alignleft aligncenter ' +
                                                                        //             'alignright alignjustify ' +
                                                                        //             ' | bullist numlist outdent indent | ' +
                                                                        //             'removeformat | help',
                                                                        //         content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                                        //     }}
                                                                        //     onEditorChange={(content: string) => {
                                                                        //         setState((prevState) => {
                                                                        //             return {
                                                                        //                 ...prevState,
                                                                        //                 emailContent: content
                                                                        //             };
                                                                        //         });
                                                                        //     }}
                                                                        // />
                                                                    )}
                                                                </Field>
                                                                {/* )} */}
                                                            </Grid>
                                                            <Grid
                                                                item={true}
                                                                xs={12}
                                                                md={12}
                                                                // className={classes.customFieldGrid + ' ' + classes.dropZone}
                                                            >
                                                                <Field
                                                                    name="files"
                                                                    // subscription={{touched: true, error: true, value: true}}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <DropzoneArea
                                                                            {...input}
                                                                            inputProps={{
                                                                                className: classes.dropZone
                                                                            }}
                                                                            // key={filesKey}
                                                                            maxFileSize={state.fileSizeLimit * 1_048_576} 
                                                                            // acceptedFiles={['.msg', '.jpg', '.jpeg', '.png', '.docx', '.zip']}
                                                                            showFileNames={true}
                                                                            useChipsForPreview={true}
                                                                            filesLimit={10}
                                                                            dropzoneText="To add a file, drag and drop or click in this box.
                                                                            Click the 'X' on a file to delete.
                                                                            Click the filename to replace it.
                                                                            View a file using the links above. 
                                                                            These links will become available once the enquiry is saved."
                                                                            dropzoneClass={classes.dropZoneFont}
                                                                            // tslint:disable-next-line: no-shadowed-variable
                                                                            onChange={(files: File[]) => {
                                                                                let existingFiles: DocumentFile[] = [...input.value];
                                                                                const newFiles = files.filter((file: File) => {
                                                                                    return !existingFiles.some((item: DocumentFile) => {
                                                                                        return item.name === file.name && item.status !== 2;
                                                                                    });
                                                                                });
                                                                                if (newFiles && newFiles.length > 0) {
                                                                                    newFiles.forEach((file: File) => {
                                                                                        const documentFile: DocumentFile = {
                                                                                            guidID: null,
                                                                                            file: file,
                                                                                            status: 1,
                                                                                            name: file.name,
                                                                                            url: ''
                                                                                        };
                                                                                        existingFiles.push(documentFile);
                                                                                    });  
                                                                                    input.onChange(existingFiles);
                                                                                }
                                                                            }}
                                                                            onDelete={(file: File) => {
                                                                                let existingFiles: DocumentFile[] = [...input.value];
                                                                                // eslint-disable-next-line array-callback-return
                                                                                existingFiles.map((item: DocumentFile) => {
                                                                                    if (file.name === item.name) {
                                                                                        item.file = file;
                                                                                        item.status = 2; // deleted
                                                                                    }
                                                                                });  
                                                                                // tslint:disable-next-line: max-line-length
                                                                                const filteredFiles = existingFiles.filter((item: DocumentFile) => {
                                                                                    return item.status !== 2 || item.guidID !== null;
                                                                                });
                                                                                input.onChange(filteredFiles);
                                                                            }}                                                    
                                                                            // tslint:disable-next-line
                                                                            onDrop={(files: File[], event: any) => {
                                                                                // let existingFiles: DocumentFile[] = [...input.value];
                                                                                // tslint:disable-next-line: max-line-length
                                                                                // const existingFile = existingFiles.find((item: DocumentFile) => item.name === file)
                                                                                // tslint:disable-next-line:no-console
                                                                                console.log(event);
                                                                            }}                                                  
                                                                            initialFiles={input.value && input.value.map((file: { url: string; }) => file.url)}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Divider className={classes.divider} />
                                                            <Grid item={true} xs={12}>
                                                                <Field
                                                                    name="followUpNotes"
                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                >
                                                                    {({ input, meta }) => (
                                                                        <TextFieldWithMention 
                                                                            {...input}
                                                                            className={classes.textField}
                                                                            label="Follow Up Notes"
                                                                            markup="@[__display__](user:__id__)"
                                                                            // tslint:disable-next-line: max-line-length
                                                                            onChange={(event: { target: { value: string } }, newValue: string, 
                                                                                       newPlainTextValue: string, mentions: MentionItem[]) => {
                                                                                input.onChange(newValue);
                                                                                if (mentions && mentions.length > 0) {
                                                                                    form.change('hasFollowUpNotesMentions', true);
                                                                                    // tslint:disable-next-line: max-line-length
                                                                                    // If we have mentions, change the Follow Up Action to - Internal Note
                                                                                    // And mark Follow Up Due as not mandatory
                                                                                    form.change('followUpAction', 'Internal Note');
                                                                                    form.change('followUpActionSystemName', 'InternalNote');
                                                                                } else {
                                                                                    form.change('hasFollowUpNotesMentions', false);
                                                                                }                                                  
                                                                            }}
                                                                            isLoadData={true}
                                                                            onlyUsedByEnquiries={true}
                                                                        />
                                                                    )}
                                                                </Field>
                                                            </Grid>
                                                            <Grid item={true} xs={12}>
                                                                <Grid container={true} spacing={2}>
                                                                    <Grid item={true} xs={12}>
                                                                        <OutcomeRadio 
                                                                            name="outcome"
                                                                            label="Outcome"
                                                                            hasNameGuid={values.nameGuid ? true : false}
                                                                            canCreateMatter={props.canCreateMatter}
                                                                            // tslint:disable-next-line: max-line-length
                                                                            onRadioButtonChange={(event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                                                                onOutcomeChange(form, values, event, checked)
                                                                            }
                                                                        />
                                                                    </Grid>                                    
                                                                    <Grid item={true} xs={12}>
                                                                        <Grid container={true} spacing={2}>
                                                                            {/* Without FormSpy, rules will not update in Realtime */}
                                                                            <Grid 
                                                                                item={true} 
                                                                                xs={12} 
                                                                                md={4} 
                                                                                // tslint:disable-next-line: max-line-length
                                                                                className={values.outcome === 'More Follow Up' ? '' : classes.hideDate}
                                                                            >
                                                                                <Field
                                                                                    name="outcomeDate"
                                                                                    // validate={values.outcome === 'More Follow Up' 
                                                                                    validate={
                                                                                        values.hasFollowUpNotesMentions 
                                                                                            // tslint:disable-next-line: max-line-length
                                                                                            ? undefined 
                                                                                            : values.followUpDate !== null && values.outcome === 'More Follow Up' 
                                                                                                ? requiredDateValidator 
                                                                                                : undefined
                                                                                    }
                                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                                >
                                                                                    {({ input, meta }) => (
                                                                                        <div>
                                                                                            <KeyboardDateTimePicker
                                                                                                {...input}
                                                                                                className={classes.textField}
                                                                                                label="Follow Up Due"
                                                                                                format="DD/MM/YYYY hh:mm a"
                                                                                                placeholder="dd/mm/yyyy hh:mm a"
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                required={values.hasFollowUpNotesMentions 
                                                                                                        // tslint:disable-next-line: max-line-length
                                                                                                        ? false 
                                                                                                        // tslint:disable-next-line: max-line-length
                                                                                                        :  values.followUpDate !== null && values.outcome === 'More Follow Up'
                                                                                                            ? true 
                                                                                                            : false
                                                                                                }
                                                                                                animateYearScrolling={true}
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                autoOk={true}
                                                                                                allowKeyboardControl={true}
                                                                                                variant={'inline'}
                                                                                                error={meta.error && meta.touched}
                                                                                                helperText={
                                                                                                    meta.error && meta.touched 
                                                                                                        ? 'Follow Up Due is required' 
                                                                                                        : ''
                                                                                                }
                                                                                                inputProps={{autocomplete: 'off'}}
                                                                                                InputLabelProps={{shrink: true}}
                                                                                                // minDate={new Date()}
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                initialFocusedDate={getOutcomeDefaultTime()}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid 
                                                                                item={true} 
                                                                                xs={12} 
                                                                                md={4} 
                                                                                alignItems="flex-end"
                                                                                className={values.outcome === 'More Follow Up' ? '' : classes.hideDate}
                                                                            >
                                                                                <Field
                                                                                    name="followUpDueReminder"
                                                                                    subscription={{touched: true, error: true, value: true}}
                                                                                >                                            
                                                                                    {({ input, meta }) => (
                                                                                        <div className={classes.selector}>
                                                                                            <ReminderSelector
                                                                                                {...input}
                                                                                                label="Reminder" 
                                                                                                className={classes.textField}
                                                                                                onSelection={(selection: IAutoCompleteItem) => {
                                                                                                    input.onChange(selection);
                                                                                                }}
                                                                                                error={meta.error && meta.touched}
                                                                                                helperText={
                                                                                                    meta.error &&
                                                                                                    meta.touched
                                                                                                        ? meta.error
                                                                                                        : ''
                                                                                                }
                                                                                                showIcon={true}
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </Field>
                                                                            </Grid>
                                                                            <Grid 
                                                                                item={true} 
                                                                                xs={6}
                                                                                md={4} 
                                                                                // tslint:disable-next-line: max-line-length
                                                                                className={values.outcome === 'More Follow Up' ? '' : classes.hideDate} 
                                                                            >
                                                                                <Field
                                                                                    name="outcomeNotes"
                                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                                >
                                                                                    {({ input, meta }) => (
                                                                                        <div>
                                                                                            <TextField
                                                                                                {...input}
                                                                                                label="Notes"
                                                                                                type="text"
                                                                                                // required={true}
                                                                                                className={classes.textField}
                                                                                                error={meta.error && meta.touched}
                                                                                                autoComplete="abcd"
                                                                                                // helperText={
                                                                                                //     meta.error &&
                                                                                                //     meta.touched
                                                                                                //         ? 'Notes is required'
                                                                                                //         : ''
                                                                                                // }
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                </Field>
                                                                            </Grid>
                                                                            {/* Without FormSpy, rules will not update in Realtime */}
                                                                            <Grid 
                                                                                item={true} 
                                                                                xs={12}
                                                                                md={4}
                                                                                // tslint:disable-next-line: max-line-length
                                                                                className={values.outcome === 'Not Proceeding' ? '' : classes.hideDate} 
                                                                            >
                                                                                <Field
                                                                                    name="reason"
                                                                                    // tslint:disable-next-line: max-line-length
                                                                                    validate={form.getFieldState('outcome')?.value === 'Not Proceeding' 
                                                                                                ? required 
                                                                                                : undefined
                                                                                    }
                                                                                    subscription={{ touched: true, error: true, value: true }}
                                                                                >
                                                                                    {({ input, meta }) => (
                                                                                        <div>
                                                                                            <ReasonSelector
                                                                                                {...input}
                                                                                                label="Not Proceeding Reason"
                                                                                                name="reason"
                                                                                                className={classes.textField}
                                                                                                error={meta.error && meta.touched}
                                                                                                disablePortal={false}
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                onSelection={(selection: IAutoCompleteItem, name: string, reasonGuid: string) => {
                                                                                                    // tslint:disable-next-line: max-line-length
                                                                                                    onDropdownChange(selection, name, input, form, reasonGuid);
                                                                                                }}
                                                                                                // tslint:disable-next-line: max-line-length
                                                                                                required={form.getFieldState('outcome')?.value === 'Not Proceeding' 
                                                                                                        ? true : false}
                                                                                                disabled={form.getFieldState('outcome')?.value !== 'Not Proceeding' 
                                                                                                        ? true : false}
                                                                                            />
                                                                                        </div>

                                                                                    )}
                                                                                </Field>
                                                                            </Grid>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                                {/* <Divider className={classes.divider} /> */}
                                                            </Grid>
                                                        </>
                                                    )}
                                                </FormSpy>
                                            </Grid>
                                            {/* {printJson(values)} */}
                                        </DialogContent>
                                        <DialogActions>
                                            <FormSpy subscription={{ values: true, pristine: true, submitting: true }}>
                                                {/* tslint:disable-next-line: no-shadowed-variable */}
                                                {({ values, pristine }) => (
                                                    <>
                                                        <Button 
                                                            // tslint:disable-next-line: max-line-length
                                                            disabled={submitting} 
                                                            color="primary"
                                                            type="submit"
                                                            onClick={() => form.submit()} 
                                                        >
                                                            Send
                                                        </Button>
                                                        
                                                        <Button 
                                                            onClick={() => onClose()} 
                                                            color="primary"
                                                        >
                                                            Cancel
                                                        </Button>
                                                    </>
                                                )}
                                            </FormSpy>                                
                                        </DialogActions>
                                    </Dialog>
                                </form>
                            )}
                        />
                    )
                }
        </React.Fragment>
    );
};

export interface TemplatedData {
    templates: Templates;
}

export interface Templates {
    emailDetail: EmailDetail;
}

export interface EmailDetail {
    id: number;
    fromAddress: string;
    toAddresses: string;
    ccAddresses: string;
    emailType: number;
    emailStyle: number;
    emailSubject: string;
    emailContent: string;
}

export interface EmailDetailQueryParams {
    emailType: string;
    emailTemplateGuid: string;
    enquiryGuid: string;
}

export function fetchEmailContent(query: EmailDetailQueryParams,
                                  refreshData: boolean,    
                                  onSuccess: (data?: TemplatedData) => void,
                                  // tslint:disable-next-line:no-any
                                  onError: (reason: any) => void)
: void {
    var fetchPolicy: FetchPolicy = refreshData === true ? 'network-only' : 'cache-first';

    client.query({
        query: EmailContentData,
        variables: {
            emailType: query.emailType,
            enquiryGuid: query.enquiryGuid,
            emailTemplateGuid: query.emailTemplateGuid
        },
        fetchPolicy: fetchPolicy
    })
    // tslint:disable-next-line:no-any
    .then((results: { data: any; }) => {
        onSuccess(results.data);
    })
    // tslint:disable-next-line:no-any
    .catch((reason: any ) => { onError(reason); });
}

export const EmailContentData = gql`
query templateWithEmailBodyForEnquiry ($emailType: EmailType, $emailTemplateGuid: String, $enquiryGuid: String) {
    templates {
        emailDetail (
            emailType: $emailType,  
            emailTemplateGuid: $emailTemplateGuid,
            enquiryGuid: $enquiryGuid
      ){
            id,
            fromAddress,
            toAddresses,
            ccAddresses,
            emailType,
            emailStyle,
            emailSubject,
            emailContent
        }
    }
}`;

/* 
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
*/
// const modules = {
//     toolbar: [
//         [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
//         [{size: []}],
//         ['bold', 'italic', 'underline', 'strike', 'blockquote'],
//         [{'list': 'ordered'}, {'list': 'bullet'}, 
//         {'indent': '-1'}, {'indent': '+1'}],
//         ['link', 'image', 'video'],
//         ['clean']
//     ],
//     clipboard: {
//         // toggle to add extra line breaks when pasting HTML:
//         matchVisual: false,
//     }
// };

/* 
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
*/
// const formats = [
//     'header', 'font', 'size',
//     'bold', 'italic', 'underline', 'strike', 'blockquote',
//     'list', 'bullet', 'indent',
//     'link', 'image', 'video'
// ];